
/* App.css */
.chart-containerr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to vertically center */
  background-color: #ffffff; /* Light background for better visibility */
}





@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}
